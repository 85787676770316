import React from "react";
// import Modal from "../utils/Modal";

function HeroHome() {
  // const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div
          className="pt-32 pb-12 md:pt-40 md:pb-20"
          style={{ display: "flex" }}
        >
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              Start feeling{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                happier
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Using CBT techniques and machine learning, Zenaholic aims to
                help you help yourself become happier and less stressed.
              </p>
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Get started today, it's free!
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/zenaholic/id1499514513"
                >
                  <div style={{ height: 50, margin: "0 25px" }}>
                    <svg
                      viewBox="0 0 119.66407 40"
                      height="100%"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m110.13477 0h-100.60009c-.3667 0-.729 0-1.09473.002-.30615.002-.60986.00781-.91895.0127a13.21476 13.21476 0 0 0 -2.0039.17671 6.66509 6.66509 0 0 0 -1.90088.627 6.43779 6.43779 0 0 0 -1.61865 1.17866 6.25844 6.25844 0 0 0 -1.17822 1.62109 6.60119 6.60119 0 0 0 -.625 1.90332 12.993 12.993 0 0 0 -.1792 2.002c-.00928.3066-.01026.61422-.01515.92086v23.11426c.00489.3105.00587.6113.01515.9219a12.99232 12.99232 0 0 0 .1792 2.0019 6.58756 6.58756 0 0 0 .625 1.9043 6.20778 6.20778 0 0 0 1.17822 1.6143 6.27445 6.27445 0 0 0 1.61865 1.1787 6.70082 6.70082 0 0 0 1.90088.6308 13.45514 13.45514 0 0 0 2.0039.1768c.30909.0068.6128.0107.91895.0107.36572.002.72805.002 1.09473.002h100.60009c.3594 0 .7246 0 1.084-.002.3047 0 .6172-.0039.9219-.0107a13.279 13.279 0 0 0 2-.1768 6.80432 6.80432 0 0 0 1.9082-.6308 6.27742 6.27742 0 0 0 1.6172-1.1787 6.39482 6.39482 0 0 0 1.1816-1.6143 6.60413 6.60413 0 0 0 .6191-1.9043 13.50643 13.50643 0 0 0 .1856-2.0019c.0039-.3106.0039-.6114.0039-.9219.0078-.3633.0078-.7246.0078-1.0938v-20.92867c0-.36621 0-.72949-.0078-1.09179 0-.30664 0-.61426-.0039-.9209a13.5071 13.5071 0 0 0 -.1856-2.002 6.6177 6.6177 0 0 0 -.6191-1.90332 6.46619 6.46619 0 0 0 -2.7988-2.7998 6.76754 6.76754 0 0 0 -1.9082-.627 13.04394 13.04394 0 0 0 -2-.17676c-.3047-.00488-.6172-.01074-.9219-.01269-.3594-.002-.7246-.002-1.084-.002z"
                        fill="#a6a6a6"
                      />
                      <path d="m8.44483 39.125c-.30468 0-.602-.0039-.90429-.0107a12.68714 12.68714 0 0 1 -1.86914-.1631 5.88381 5.88381 0 0 1 -1.65674-.5479 5.40573 5.40573 0 0 1 -1.397-1.0166 5.32082 5.32082 0 0 1 -1.02051-1.3965 5.72186 5.72186 0 0 1 -.543-1.6572 12.41351 12.41351 0 0 1 -.1665-1.875c-.00634-.2109-.01464-.9131-.01464-.9131v-23.10056s.00884-.69141.01469-.89454a12.37039 12.37039 0 0 1 .16553-1.87207 5.7555 5.7555 0 0 1 .54346-1.6621 5.37349 5.37349 0 0 1 1.01514-1.39795 5.56543 5.56543 0 0 1 1.40234-1.02247 5.82309 5.82309 0 0 1 1.65332-.54394 12.58589 12.58589 0 0 1 1.87551-.16406l.90232-.01221h102.76855l.9131.0127a12.38493 12.38493 0 0 1 1.8584.16259 5.93833 5.93833 0 0 1 1.6709.54785 5.59374 5.59374 0 0 1 2.415 2.41993 5.76267 5.76267 0 0 1 .5352 1.64892 12.995 12.995 0 0 1 .1738 1.88721c.0029.2832.0029.5874.0029.89014.0079.375.0079.73193.0079 1.09179v20.92867c0 .3633 0 .7178-.0079 1.0752 0 .3252 0 .6231-.0039.9297a12.73126 12.73126 0 0 1 -.1709 1.8535 5.739 5.739 0 0 1 -.54 1.67 5.48029 5.48029 0 0 1 -1.0156 1.3857 5.4129 5.4129 0 0 1 -1.3994 1.0225 5.86168 5.86168 0 0 1 -1.668.5498 12.54218 12.54218 0 0 1 -1.8692.1631c-.2929.0068-.5996.0107-.8974.0107l-1.084.002z" />
                      <g fill="#fff">
                        <path d="m24.76888 20.30068a4.94881 4.94881 0 0 1 2.35656-4.15206 5.06566 5.06566 0 0 0 -3.99116-2.15768c-1.67924-.17626-3.30719 1.00483-4.1629 1.00483-.87227 0-2.18977-.98733-3.6085-.95814a5.31529 5.31529 0 0 0 -4.47292 2.72787c-1.934 3.34842-.49141 8.26947 1.3612 10.97608.9269 1.32535 2.01018 2.8058 3.42763 2.7533 1.38706-.05753 1.9051-.88448 3.5794-.88448 1.65876 0 2.14479.88448 3.591.8511 1.48838-.02416 2.42613-1.33124 3.32051-2.66914a10.962 10.962 0 0 0 1.51842-3.09251 4.78205 4.78205 0 0 1 -2.91924-4.39917z" />
                        <path d="m22.03725 12.21089a4.87248 4.87248 0 0 0 1.11452-3.49062 4.95746 4.95746 0 0 0 -3.20758 1.65961 4.63634 4.63634 0 0 0 -1.14371 3.36139 4.09905 4.09905 0 0 0 3.23677-1.53038z" />
                        <path d="m42.30227 27.13965h-4.7334l-1.13672 3.35645h-2.00488l4.4834-12.418h2.083l4.4834 12.418h-2.03907zm-4.24317-1.54883h3.752l-1.84961-5.44727h-.05176z" />
                        <path d="m55.15969 25.96973c0 2.81348-1.50586 4.62109-3.77832 4.62109a3.0693 3.0693 0 0 1 -2.84863-1.584h-.043v4.48438h-1.8584v-12.04882h1.79886v1.50586h.03418a3.21162 3.21162 0 0 1 2.88281-1.60059c2.29781.00001 3.8125 1.81641 3.8125 4.62208zm-1.91016 0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992 0-2.375 1.23047-2.375 3.03809 0 1.82422.95508 3.0459 2.375 3.0459 1.44532 0 2.39258-1.19629 2.39258-3.0459z" />
                        <path d="m65.12453 25.96973c0 2.81348-1.50586 4.62109-3.77832 4.62109a3.0693 3.0693 0 0 1 -2.84863-1.584h-.043v4.48438h-1.8584v-12.04882h1.79882v1.50586h.03418a3.21162 3.21162 0 0 1 2.88282-1.60058c2.29788 0 3.81253 1.8164 3.81253 4.62207zm-1.91016 0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992 0-2.375 1.23047-2.375 3.03809 0 1.82422.95508 3.0459 2.375 3.0459 1.44532 0 2.39259-1.19629 2.39259-3.0459z" />
                        <path d="m71.71047 27.03613c.1377 1.23145 1.334 2.04 2.96875 2.04 1.56641 0 2.69336-.80859 2.69336-1.91895 0-.96387-.67969-1.541-2.28906-1.93652l-1.60937-.3877c-2.28027-.55078-3.33887-1.61719-3.33887-3.34766 0-2.14258 1.86719-3.61426 4.51855-3.61426 2.624 0 4.42285 1.47168 4.4834 3.61426h-1.876c-.1123-1.23926-1.13672-1.9873-2.63379-1.9873s-2.52148.75684-2.52148 1.8584c0 .87793.6543 1.39453 2.25488 1.79l1.36816.33594c2.54785.60254 3.60645 1.626 3.60645 3.44238 0 2.32324-1.85059 3.77832-4.79395 3.77832-2.75391 0-4.61328-1.4209-4.7334-3.667z" />
                        <path d="m83.34621 19.2998v2.14258h1.72168v1.47168h-1.72168v4.99121c0 .77539.34473 1.13672 1.10156 1.13672a5.80752 5.80752 0 0 0 .61133-.043v1.46289a5.10351 5.10351 0 0 1 -1.03223.08594c-1.833 0-2.54785-.68848-2.54785-2.44434v-5.18942h-1.3164v-1.47168h1.31638v-2.14258z" />
                        <path d="m86.065 25.96973c0-2.84863 1.67773-4.63867 4.29395-4.63867 2.625 0 4.29492 1.79 4.29492 4.63867 0 2.85645-1.66113 4.63867-4.29492 4.63867-2.63286 0-4.29395-1.78223-4.29395-4.63867zm6.69531 0c0-1.9541-.89551-3.10742-2.40137-3.10742s-2.40039 1.16211-2.40039 3.10742c0 1.96191.89453 3.10645 2.40039 3.10645s2.40133-1.14454 2.40133-3.10645z" />
                        <path d="m96.18606 21.44238h1.77246v1.541h.043a2.1594 2.1594 0 0 1 2.17773-1.63574 2.86616 2.86616 0 0 1 .63672.06934v1.73828a2.59794 2.59794 0 0 0 -.835-.1123 1.87264 1.87264 0 0 0 -1.93652 2.083v5.37012h-1.8584z" />
                        <path d="m109.3843 27.83691c-.25 1.64355-1.85059 2.77148-3.89844 2.77148-2.63379 0-4.26855-1.76465-4.26855-4.5957 0-2.83984 1.64355-4.68164 4.19043-4.68164 2.50488 0 4.08008 1.7207 4.08008 4.46582v.63672h-6.39453v.1123a2.358 2.358 0 0 0 2.43555 2.56445 2.04834 2.04834 0 0 0 2.09082-1.27344zm-6.28223-2.70215h4.52637a2.1773 2.1773 0 0 0 -2.2207-2.29785 2.292 2.292 0 0 0 -2.30567 2.29786z" />
                        <path d="m37.82619 8.731a2.63964 2.63964 0 0 1 2.80762 2.96484c0 1.90625-1.03027 3.002-2.80762 3.002h-2.15527v-5.96684zm-1.22852 5.123h1.125a1.87588 1.87588 0 0 0 1.96777-2.146 1.881 1.881 0 0 0 -1.96777-2.13379h-1.125z" />
                        <path d="m41.68068 12.44434a2.13323 2.13323 0 1 1 4.24707 0 2.13358 2.13358 0 1 1 -4.24707 0zm3.333 0c0-.97607-.43848-1.54687-1.208-1.54687-.77246 0-1.207.5708-1.207 1.54688 0 .98389.43457 1.55029 1.207 1.55029.76954-.00001 1.20801-.57032 1.20801-1.5503z" />
                        <path d="m51.57326 14.69775h-.92187l-.93066-3.31641h-.07031l-.92676 3.31641h-.91309l-1.24121-4.50293h.90137l.80664 3.436h.06641l.92578-3.436h.85254l.92578 3.436h.07031l.80273-3.436h.88867z" />
                        <path d="m53.85354 10.19482h.85546v.71533h.06641a1.348 1.348 0 0 1 1.34375-.80225 1.46456 1.46456 0 0 1 1.55859 1.6748v2.915h-.88867v-2.69184c0-.72363-.31445-1.0835-.97168-1.0835a1.03294 1.03294 0 0 0 -1.0752 1.14111v2.63428h-.88867z" />
                        <path d="m59.09377 8.437h.88867v6.26074h-.88867z" />
                        <path d="m61.21779 12.44434a2.13346 2.13346 0 1 1 4.24756 0 2.1338 2.1338 0 1 1 -4.24756 0zm3.333 0c0-.97607-.43848-1.54687-1.208-1.54687-.77246 0-1.207.5708-1.207 1.54688 0 .98389.43457 1.55029 1.207 1.55029.76953-.00001 1.20801-.57032 1.20801-1.5503z" />
                        <path d="m66.4009 13.42432c0-.81055.60352-1.27783 1.6748-1.34424l1.21973-.07031v-.38867c0-.47559-.31445-.74414-.92187-.74414-.49609 0-.83984.18213-.93848.50049h-.86035c.09082-.77344.81836-1.26953 1.83984-1.26953 1.12891 0 1.76563.562 1.76563 1.51318v3.07666h-.85547v-.63281h-.07031a1.515 1.515 0 0 1 -1.35254.707 1.36026 1.36026 0 0 1 -1.50098-1.34763zm2.89453-.38477v-.37646l-1.09961.07031c-.62012.0415-.90137.25244-.90137.64941 0 .40527.35156.64111.835.64111a1.0615 1.0615 0 0 0 1.16598-.98437z" />
                        <path d="m71.34816 12.44434c0-1.42285.73145-2.32422 1.86914-2.32422a1.484 1.484 0 0 1 1.38086.79h.06641v-2.47312h.88867v6.26074h-.85156v-.71143h-.07031a1.56284 1.56284 0 0 1 -1.41406.78564c-1.14551.00005-1.86915-.90134-1.86915-2.32761zm.918 0c0 .95508.4502 1.52979 1.20313 1.52979.749 0 1.21191-.583 1.21191-1.52588 0-.93848-.46777-1.52979-1.21191-1.52979-.74808 0-1.20316.57861-1.20316 1.52588z" />
                        <path d="m79.23 12.44434a2.13323 2.13323 0 1 1 4.24707 0 2.13358 2.13358 0 1 1 -4.24707 0zm3.333 0c0-.97607-.43848-1.54687-1.208-1.54687-.77246 0-1.207.5708-1.207 1.54688 0 .98389.43457 1.55029 1.207 1.55029.76953-.00001 1.208-.57032 1.208-1.5503z" />
                        <path d="m84.66945 10.19482h.85547v.71533h.06641a1.348 1.348 0 0 1 1.34375-.80225 1.46456 1.46456 0 0 1 1.55859 1.6748v2.915h-.88867v-2.69184c0-.72363-.31445-1.0835-.97168-1.0835a1.03294 1.03294 0 0 0 -1.0752 1.14111v2.63428h-.88867z" />
                        <path d="m93.51516 9.07373v1.1416h.97559v.74854h-.97559v2.31543c0 .47168.19434.67822.63672.67822a2.96657 2.96657 0 0 0 .33887-.02051v.74023a2.9155 2.9155 0 0 1 -.4834.04541c-.98828 0-1.38184-.34766-1.38184-1.21582v-2.543h-.71484v-.74854h.71484v-1.14156z" />
                        <path d="m95.70461 8.437h.88086v2.48145h.07031a1.3856 1.3856 0 0 1 1.373-.80664 1.48339 1.48339 0 0 1 1.55078 1.67871v2.90723h-.88956v-2.688c0-.71924-.335-1.0835-.96289-1.0835a1.05194 1.05194 0 0 0 -1.13379 1.1416v2.62988h-.88867z" />
                        <path d="m104.76125 13.48193a1.828 1.828 0 0 1 -1.95117 1.30273 2.04531 2.04531 0 0 1 -2.08008-2.32421 2.07685 2.07685 0 0 1 2.07617-2.35254c1.25293 0 2.00879.856 2.00879 2.27v.31009h-3.17969v.0498a1.1902 1.1902 0 0 0 1.19922 1.29 1.07934 1.07934 0 0 0 1.07129-.5459zm-3.126-1.45117h2.27441a1.08647 1.08647 0 0 0 -1.1084-1.1665 1.15162 1.15162 0 0 0 -1.16599 1.1665z" />
                      </g>
                    </svg>
                  </div>
                </a>
                <div style={{ height: 50, margin: "0 25px" }}>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=studio.ae.quokka"
                  >
                    <img
                      src={require("../images/google-play-badge.png")}
                      style={{ maxHeight: "100%" }}
                      alt="google play"
                    />
                  </a>
                </div>
              </div>
              <div style={{ marginTop: 100 }}>
                Made with{" "}
                <span role="img" aria-label="heart">
                  ❤️
                </span>{" "}
                by{" "}
                <a
                  href="https://ae.studio"
                  style={{ textDecoration: "underline" }}
                >
                  AE Studio
                </a>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto"
                  src={require("../images/zenaholic-screenshot.png")}
                  alt="Screenshot"
                  style={{ maxHeight: 600 }}
                />
              </div>
              {/* <button
                className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg"
                onClick={(e) => {
                  e.preventDefault();
                  setVideoModalOpen(true);
                }}
                aria-controls="modal"
              >
                <svg
                  className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 flex-shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span className="ml-3">Watch the full video (2 min)</span>
              </button> */}
            </div>

            {/* Modal */}
            {/* <Modal
              id="modal"
              ariaLabel="modal-headline"
              show={videoModalOpen}
              handleClose={() => setVideoModalOpen(false)}
            >
              <div className="relative pb-9/16">
                <iframe
                  className="absolute w-full h-full"
                  src="https://player.vimeo.com/video/174002812"
                  title="Video"
                  allowFullScreen
                ></iframe>
              </div>
            </Modal> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
