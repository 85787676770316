import React from "react";
import Helmet from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      {/* Termly Tracking Code */}

      <div
        name="termly-embed"
        data-id="77171cf8-1554-423f-bc9e-7521adf448b0"
        data-type="iframe"
      ></div>

      <Helmet>
        <script type="text/javascript">{`(function(d, s, id) {
  var js, tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
}(document, 'script', 'termly-jssdk'))`}</script>
      </Helmet>
    </>
  );
}

export default PrivacyPolicy;
